/* eslint-disable jsx-a11y/img-redundant-alt */
/* eslint-disable no-undef */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable no-unused-vars */

import React, { useState, useEffect } from "react";

import Breadcrumbs from "../../../../../components/Breadcrumbs";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import AddSalaryModal from "../../../../../components/modelpopup/AddSalaryModal";
import SalaryTable from "./SalaryTable";

import { useTranslation } from "react-i18next";

import AuthUser from "../../../../../components/AuthUser";

import { toast, ToastContainer } from "react-toastify";

import Spinner from "../../../../../components/Spinner";

import { startOfMonth, endOfMonth, format } from "date-fns";

import { Link, useParams, useNavigate } from "react-router-dom";
import AddColaboradorFolhaSalarialModal from "../../../../../components/modelpopup/AddColaboradorFolhaSalarialModal";

import Swal from "sweetalert2";
import RegistarPagamentoFolhaSalarialConjuntaModal from "../../../../../components/modelpopup/RegistarPagamentoFolhaSalarialConjuntaModal";
import RegistarPagamentoFolhaSalarialUnicaModal from "../../../../../components/modelpopup/RegistarPagamentoFolhaSalarialUnicaModal";
import AlterarBancoFolhaSalarialModal from "../../../../../components/modelpopup/AlterarBancoFolhaSalarialModal";

const EmployeeSalarySheet = () => {
  const navigate = useNavigate();
  // navigate('/admin-dashboard');

  let { FolhaID } = useParams();

  const { t } = useTranslation();

  // eslint-disable-next-line no-unused-vars
  const { http, url_root, verificarPermissao, user, getMissao } = AuthUser();
  const [loading, setLoading] = useState(false);

  const [feria_natal, setFeriaNatal] = useState(1);
  const [processados, setProcessados] = useState([]);
  const [salarios, setSalarios] = useState([]);
  const [bancos, setBancos] = useState([]);
  const [folha, setFolha] = useState([]);
  const [colaboradores, setColaboradores] = useState([]);
  const [selectedFolhas, setSelectedFolhas] = useState([]);

  const [selectedEmployees, setSelectedEmployees] = useState([]);

  const [isAllSelected, setIsAllSelected] = useState(false);


  const [setSelectedOption] = useState(null);
  const [setSelecttwo] = useState(null);

  const [focused, setFocused] = useState(false);
  const [inputValue, setInputValue] = useState("");

  const formatNumber = (number) => {
    return new Intl.NumberFormat("pt-PT", {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(number);
  };

  useEffect(() => {

    setSelectedFolhas([]);
    setSelectedFolhas((prevState) => [...prevState, { id: 0 }]);
    setSelectedFolhas((prevState) => [...prevState, { id: FolhaID }]);

    const fetchData = async () => {
      try {
        await getSalariosDaFolha();
      } catch (error) {
        console.error("Erro ao obter utilizadores:", error);
      }
    };

    fetchData();
  }, []);

  const handleSelectAll = () => {
    if (isAllSelected) {
      // Desmarcar todos
      setSelectedEmployees([]);
    } else {
      // Selecionar todos
      const allEmployeeIds = salarios.map((item) => item?.colaborador?.id);
      setSelectedEmployees(allEmployeeIds);
    }
    setIsAllSelected(!isAllSelected); // Alternar o estado
  };


  // Método para adicionar ou remover o colaborador do array selectedEmployees
  const handleRowClick = (colaboradorId) => {
    setSelectedEmployees(
      (prevSelected) =>
        prevSelected.includes(colaboradorId)
          ? prevSelected.filter((id) => id !== colaboradorId) // Remove se já estiver selecionado
          : [...prevSelected, colaboradorId] // Adiciona se não estiver selecionado
    );
  };

  const submitFolhaSlarialExcelreport = async (folha_id) => {
    try {
      setLoading(true);

      await getReportFolhaExcel(folha_id);

      setLoading(false);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Show a error toast
        toast.error("Utilizador não autenticado.", {
          position: "top-right",
          autoClose: 3000, // Close the toast after 3 seconds
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      } else {
        // Show a error toast
        toast.error(
          "Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado",
          {
            position: "top-right",
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        );
      }
    } finally {
      setLoading(false);
    }
  };

  const getReportFolhaExcel = async (folha_id) => {
    try {
      setLoading(true);

      const formData = new FormData();

      formData.append("folha_id", folha_id);

      const response = await http.post("/folha_salarial/excel", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      console.log("response", response.data.url);

      setLoading(false);

      // Open the response URL in a new tab
      window.open(url_root + "" + response?.data?.url, "_blank");
    } catch (error) {
      console.error(error);

      toast.error(
        "Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.",
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );
    }
  };

  const adicionarFolha = () => {
    if (folha) {
      setSelectedFolhas([...selectedFolhas, folha]);
    }
  };

  const getSalariosDaFolha = async () => {
    try {
      setLoading(true); // Set loading to true when the request starts

      const res = await http.get("/folha-salarial/salarios/" + FolhaID);

      setSalarios(res?.data?.salarios);
      setBancos(res?.data?.bancos);
      setProcessados(res?.data?.processados);
      setFolha(res?.data?.folha);
      setColaboradores(res?.data?.colaboradores);

      adicionarFolha(res?.data?.folha);

      setLoading(false);
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const handleOnSubmitSuccess = () => {
    getSalariosDaFolha();
  };

  const definir_assets = (res) => {
    try {
      setSalarios(res?.data?.salarios);
      setProcessados(res?.data?.processados);
      setFolha(res?.data?.folha);
      setColaboradores(res?.data?.colaboradores);
    } finally {
      setLoading(false); // Set loading to false when the request is complete (either success or error)
    }
  };

  const chamarModalEliminar = (colaborador_id) => {
    handleEliminar(colaborador_id);
  };

  const handleEliminar = async (colaborador_id) => {
    Swal.fire({
      title: "Remover colaborador da folha salarial?",
      text: "Tem a certeza que pretende remover este colaborador desta folha?",
      type: "warning",
      showCancelButton: !0,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Sim, remover!",
      confirmButtonClass: "btn btn-primary",
      cancelButtonClass: "btn btn-danger ml-1",
      buttonsStyling: !1,
    }).then(async function (t) {
      if (t.isConfirmed) {
        try {
          setLoading(true);

          try {
            const formData = new FormData();
            formData.append("colaborador_id", colaborador_id);
            formData.append("folha_id", folha?.id);

            const res = await http.post(
              "/folha-salarial/colaborador/eliminar",
              formData,
              {
                headers: { "Content-Type": "multipart/form-data" },
              }
            );

            setSalarios(res?.data?.salarios);
            setProcessados(res?.data?.processados);
            setFolha(res?.data?.folha);
            setColaboradores(res?.data?.colaboradores);

            setLoading(false);

            // Atualize a lista de acessos após ativar/desativar
          } catch (error) {
            setLoading(false);
            console.error(error);

            toast.error(
              "Ocorreu um erro ao eliminar o estado do usuário. Por favor, tente novamente mais tarde.",
              {
                position: "top-right",
                autoClose: 3000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              }
            );
          } finally {
            setLoading(false);
          }

          setLoading(false);
        } catch (error) {
          setLoading(false);

          toast.error(
            "Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.",
            {
              position: "top-right",
              autoClose: 1000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            }
          );
        }
      }
    });
  };

  const chamarModalRegistarPagamentoConjunta = () => {
    const editButton = document.getElementById("triggerMarcarFaltaConjunta");

    if (editButton) {
      editButton.click();
    }
  };

  const chamarModalAlterarBanco = () => {
    const editButton = document.getElementById("triggerModalAlterarBanco");

    if (editButton) {
      editButton.click();
    }
  };

  const submitFolhaSlarialPDFreport = async () => {
    try {
      setLoading(true);

      await getReportFolha();

      setLoading(false);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        setError("Utilizador não autenticado.");

        // Show a error toast
        toast.error("Utilizador não autenticado.", {
          position: "top-right",
          autoClose: 3000, // Close the toast after 3 seconds
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      } else {
        setError(
          "Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado"
        );

        // Show a error toast
        toast.error(
          "Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado",
          {
            position: "top-right",
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        );
      }
    } finally {
      setLoading(false);
    }
  };

  const getReportFolha = async () => {
    try {
      setLoading(true);

      const formData = new FormData();

      formData.append("folha_id", folha?.id);

      const response = await http.post("/folha_salarial/pdf", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      console.log("response", response.data.url);

      setLoading(false);

      // Open the response URL in a new tab
      window.open(response?.data?.url, "_blank");
    } catch (error) {
      console.error(error);

      toast.error(
        "Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.",
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );
    }
  };

  const submitReciboPDFreport = async (recibo_id) => {
    try {
      setLoading(true);

      await getReportRecibo(recibo_id);

      setLoading(false);
    } catch (error) {
      if (error.response && error.response.status === 401) {
        // Show a error toast
        toast.error("Utilizador não autenticado.", {
          position: "top-right",
          autoClose: 3000, // Close the toast after 3 seconds
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
      } else {
        // Show a error toast
        toast.error(
          "Ocorreu um erro inesperado. Por favor, tente novamente mais tarde. Verifique tambem se o e-mail ja esta registado",
          {
            position: "top-right",
            autoClose: 3000, // Close the toast after 3 seconds
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        );
      }
    } finally {
      setLoading(false);
    }
  };

  const getReportRecibo = async (recibo_id) => {
    try {
      setLoading(true);

      const formData = new FormData();

      formData.append("recibo_id", recibo_id);

      const response = await http.post("/recibo_salarial/pdf", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      console.log("response", response.data.url);

      setLoading(false);

      // Open the response URL in a new tab
      window.open(response?.data?.url, "_blank");
    } catch (error) {
      console.error(error);

      toast.error(
        "Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.",
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );
    }
  };



  const handleAtualizarSelecionados = async () => {

    if (selectedEmployees.length > 0) {

      Swal.fire({
        title: "Atualizar sálario dos colaboradores ?",
        text: "Tem a certeza que pretende atualizar o processamento de salario deste(s) colaborador(es) ?",
        type: "warning",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, atualizar!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      }).then(async function (t) {
        if (t.isConfirmed) {
          try {
            setLoading(true);

            try {

              const formData = new FormData();

              formData.append("folha_id", folha?.id);
              formData.append("feria", (feria_natal == 3 || feria_natal == 4) ? 1 : 0);
              formData.append("natal", (feria_natal == 2 || feria_natal == 4) ? 1 : 0);
              formData.append(
                "colaboradores",
                JSON.stringify(selectedEmployees)
              );

              const response = await http.post(
                "/folha-salarial/atualizar-colaborador",
                formData,
                {
                  headers: { "Content-Type": "multipart/form-data" },
                }
              );

              setLoading(false);

              if (response?.data) {
                definir_assets(response);
              }

              setLoading(false);

              // Atualize a lista de acessos após ativar/desativar
            } catch (error) {
              setLoading(false);
              console.error(error);

              toast.error(
                "Ocorreu um erro ao eliminar o estado do usuário. Por favor, tente novamente mais tarde.",
                {
                  position: "top-right",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                }
              );
            } finally {
              setLoading(false);
            }

            setLoading(false);
          } catch (error) {
            setLoading(false);

            toast.error(
              "Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.",
              {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              }
            );
          }
        }
      });
    } else {
      toast.error("Deve selecionar pelo menos um colaborador.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const handleRemoverSelecionados = async () => {

    if (selectedEmployees.length > 0) {

      Swal.fire({
        title: "Remover sálario dos colaboradores ?",
        text: "Tem a certeza que pretende remover o processamento de salario deste(s) colaborador(es) ?",
        type: "warning",
        showCancelButton: !0,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Sim, remover!",
        confirmButtonClass: "btn btn-primary",
        cancelButtonClass: "btn btn-danger ml-1",
        buttonsStyling: !1,
      }).then(async function (t) {
        if (t.isConfirmed) {
          try {
            setLoading(true);

            try {

              const formData = new FormData();

              formData.append("folha_id", folha?.id);
              formData.append(
                "colaboradores",
                JSON.stringify(selectedEmployees)
              );

              const response = await http.post(
                "/folha-salarial/remover-colaborador/selecionados",
                formData,
                {
                  headers: { "Content-Type": "multipart/form-data" },
                }
              );

              setLoading(false);

              if (response?.data) {
                definir_assets(response);
              }

              setLoading(false);

              // Atualize a lista de acessos após ativar/desativar
            } catch (error) {
              setLoading(false);
              console.error(error);

              toast.error(
                "Ocorreu um erro ao eliminar o estado do usuário. Por favor, tente novamente mais tarde.",
                {
                  position: "top-right",
                  autoClose: 3000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                }
              );
            } finally {
              setLoading(false);
            }

            setLoading(false);
          } catch (error) {
            setLoading(false);

            toast.error(
              "Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.",
              {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
              }
            );
          }
        }
      });
    } else {
      toast.error("Deve selecionar pelo menos um colaborador.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };


  const submitRecibosMultiplosPDF = async () => {
      try {
        setLoading(true);
  
        try {

          const formData = new FormData();

          formData.append("missao_id", getMissao()?.id);
          formData.append("folhas", JSON.stringify(selectedFolhas));
  
          const response = await http.post(
            "/recibos/multiplos/pdf",
            formData,
            {
              headers: { "Content-Type": "multipart/form-data" },
            }
          );
  
          console.log("response", response.data.url);
  
          setLoading(false);
  
          // Open the response URL in a new tab
          window.open(response?.data?.url, "_blank");
  
          // Atualize a lista de acessos após ativar/desativar
        } catch (error) {
          console.error(error);
  
          toast.error(
            "Ocorreu um erro ao eliminar o estado do usuário. Por favor, tente novamente mais tarde.",
            {
              position: "top-right",
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            }
          );
        } finally {
          setLoading(false);
        }
  
        setLoading(false);
      } catch (error) {
        setLoading(false);
  
        toast.error(
          "Ocorreu um erro ao carregar o utilizador. Por favor, tente novamente mais tarde.",
          {
            position: "top-right",
            autoClose: 1000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
          }
        );
      }
    };

  return (
    <>
      <div className="page-wrapper">
        <div className="content container-fluid">
          <Breadcrumbs
            maintitle={t("Employee Salary Sheet")}
            title="Dashboard"
            subtitle={t("Salary")}
            modal="#add_colaborador_folha"
            name={t("Add Colaborador")}
          />

          <div className="row mb-4">

            <div className="col-sm-12 col-md-4 col-lg-4 col-xl-2">
              <span
                className="btn btn-dark"
                onClick={() => handleAtualizarSelecionados()}
              >
                <i className="fa fa-recycle text-white" /> {t("Atualizar selecionados")}
              </span>
              <div className="input-block form-focus select-focus">
                <label className="focus-label">{t("Ao atualizar adicionar Sub. Natal e Feria")}</label>
                <select
                  onChange={(e) => setFeriaNatal(e.target.value)}
                  value={feria_natal}
                  className="form-control select"
                >
                  <option value={-1}></option>
                  <option value="1">Nenhum dos dois</option>
                  <option value="2">Incluir Natal</option>
                  <option value="3">Incluir Férias</option>
                  <option value="4">Incluir Ambos</option>
                </select>
              </div>
            </div>


            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-2">
              <span
                className="btn btn-primary"
                onClick={() => chamarModalAlterarBanco()}
              >
                <i className="fa fa-bank text-white" /> {t("Alterar O Banco")}
              </span>
            </div>

            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-2">
              <span
                title={folha?.designacao}
                className="btn btn-success"
                onClick={() => submitFolhaSlarialExcelreport(folha?.id)}
              >
                <i className="fa fa-file-excel"></i>&nbsp;Exportar Excel
              </span>
            </div>

            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-2">
              <span
                title={folha?.designacao}
                className="btn btn-danger"
                onClick={() => submitFolhaSlarialPDFreport()}
              >
                <i className="fa fa-file-pdf"></i>&nbsp;Exportar PDF
              </span>
            </div>

            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-2">
              <span
                title={'Exportar todos os recibos desta folha'}
                className="btn btn-danger"
                onClick={() => submitRecibosMultiplosPDF()}
              >
                <i className="fa fa-file-pdf"></i>&nbsp;Exportar Recibos PDF
              </span>
            </div>

            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-2">
              <span
                className="btn btn-danger"
                onClick={() => handleRemoverSelecionados()}
              >
                <i className="fa fa-trash text-white" /> {t("Remover Selecionados")}
              </span>
            </div>

            <div className="col-sm-12 col-md-6 col-lg-6 col-xl-2">
              {folha?.paga != 1 ? (
                <>
                  <span
                    className="btn btn-white btn-rounded"
                    onClick={() => chamarModalRegistarPagamentoConjunta()}
                  >
                    <i className="far fa-dot-circle text-danger" />{" "}
                    {t("Not Paid")}
                  </span>
                </>
              ) : (
                <>
                  <span
                    className="btn btn-white btn-rounded"
                    onClick={() => chamarModalRegistarPagamentoConjunta()}
                    title={format(
                      new Date(folha?.data_pagamento),
                      "dd/MM/yyyy"
                    )}
                  >
                    <i className="far fa-dot-circle text-success" /> {t("Paid")}{" "}
                    - Em {format(new Date(folha?.data_pagamento), "dd/MM/yyyy")}
                  </span>
                </>
              )}
            </div>
          </div>

          <table
            className="table table-striped table-responsive"
            style={{ overflowX: "auto" }}
          >
            <thead>
              <tr>
                <th colSpan={7}>

                  <Link
                    to={"#"}
                    className="avatar"
                  >
                    <img
                      alt=""
                      src={
                        url_root +
                        "/storage/" +
                        folha?.logo
                      }
                    />
                  </Link>
                  {folha?.designacao?.toUpperCase()} - [{" "}
                  {folha?.banco?.nome?.toUpperCase()} ]
                </th>
              </tr>
            </thead>
          </table>

          <table
            className="table table-striped table-responsive"
            style={{ overflowX: "auto" }}
          >
            <thead>
              <tr>
                <th
                  title="selecionar/remover seleção"
                  onClick={handleSelectAll}
                  style={{ cursor: "pointer" }}
                >
                  #
                </th>

                <th>Nome</th>
                <th>Função/Tel</th>
                <th>CONTA</th>
                <th>IBAN</th>
                <th>Salário (kz)</th>
                <th>Recibo</th>
                <th>
                  <i className="fa fa-cogs"></i>
                </th>
              </tr>
            </thead>
            <tbody>
              {salarios?.slice(0, 356).map((item, index) => (
                <tr
                  key={index}
                  title={item?.colaborador?.name}
                  className={`selectable ${selectedEmployees.includes(item?.colaborador?.id)
                    ? "selected"
                    : ""
                    }  ${item?.colaborador?.estado != 1 ? "desativado" : ""}`}
                  onClick={() => handleRowClick(item?.colaborador?.id)}
                >
                  <td style={{ backgroundColor: "transparent" }}>{index + 1}</td>
                  <td style={{ backgroundColor: "transparent" }}>
                    <div className="table-avatar">
                      <Link
                        to={"/profile/" + item?.colaborador_id}
                        className="avatar"
                      >
                        <img
                          alt=""
                          src={
                            url_root +
                            "/storage/" +
                            item?.colaborador?.fotografia
                          }
                        />
                      </Link>
                      <Link to={"/profile/" + item?.colaborador_id}>
                        <span>{item?.colaborador?.name}</span>
                      </Link>
                    </div>
                  </td>
                  <td style={{ backgroundColor: "transparent" }}>
                    {item?.cargo}
                    <br></br>
                    {item?.colaborador?.telefone}
                  </td>
                  <td style={{ backgroundColor: "transparent" }}>{item?.conta}</td> {/* Nome do mês */}
                  <td style={{ backgroundColor: "transparent" }}>{item?.IBAN}</td>
                  <td style={{ backgroundColor: "transparent" }}>{formatNumber(item?.salario_iliquido)}</td>
                  <td style={{ backgroundColor: "transparent" }}>
                    <Link
                      className="dropdown-item"
                      to={"/salary-view/" + item?.id}
                    >
                      <i className="fa fa-eye m-r-5" /> {t("Ver Recibo")}
                    </Link>
                  </td>
                  <td>
                    <div className="dropdown dropdown-action text-end">
                      <Link
                        to="#"
                        className="action-icon dropdown-toggle"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <i className="material-icons">more_vert</i>
                      </Link>

                      <div className="dropdown-menu dropdown-menu-right">
                        <span
                          className="btn btn-sm btn-secondary text-white"
                          onClick={() => submitReciboPDFreport(item?.id)}
                        >
                          <i className="fa fa-file-pdf"></i>&nbsp;PDF
                        </span>

                        {item?.paga != 1 ? (
                          <>
                            <span
                              className="dropdown-item"
                              onClick={() =>
                                chamarModalEliminar(item?.colaborador_id)
                              }
                            >
                              <i className="fa fa-trash m-r-5" />{" "}
                              {t("Remover Da Folha")}
                            </span>
                          </>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
            <thead>
              <tr>
                <th>#</th>
                <th></th>
                <th></th>
                <th></th>
                <th></th>
                <th className="bg-primary text-dark">
                  {formatNumber(folha?.total)}
                </th>
                <th></th>
                <th></th>
              </tr>
            </thead>
          </table>

          {loading ? (
            <>
              <Spinner></Spinner>
            </>
          ) : (
            <></>
          )}
        </div>
      </div>

      <AddColaboradorFolhaSalarialModal
        utilizadores={colaboradores}
        salarios={salarios}
        processados={processados}
        folha={folha}
        onSubmissionSuccess={definir_assets}
      />

      <span
        data-bs-toggle="modal"
        data-bs-target="#marcar_falta_conjunta_modal"
        id="triggerMarcarFaltaConjunta"
      ></span>

      <span
        data-bs-toggle="modal"
        data-bs-target="#alterar_banco_modal"
        id="triggerModalAlterarBanco"
      ></span>

      <RegistarPagamentoFolhaSalarialUnicaModal
        folha_id={folha?.id}
        onSubmitSuccess={handleOnSubmitSuccess}
      />

      <AlterarBancoFolhaSalarialModal
        folha_id={folha?.id}
        bancos={bancos}
        onSubmitSuccess={handleOnSubmitSuccess}
      />
    </>
  );
};

export default EmployeeSalarySheet;
