import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";

import { useTranslation } from "react-i18next";

import AuthUser from "../../../../components/AuthUser";
import { toast, ToastContainer } from "react-toastify";
import Spinner from "../../../../components/Spinner";
import MarcarFaltaModal from "../../../../components/modelpopup/MarcarFaltaModal";
import MarcarFaltaConjuntaModal from "../../../../components/modelpopup/MarcarFaltaConjuntaModal";
import MarcarFeriaConjuntaModal from "../../../../components/modelpopup/MarcarFeriaConjuntaModal";

const FeriaFilter = ({ onFilterSubmit, tipo, departamentos, cargos, utilizadoresSelecionados }) => {
  const { t } = useTranslation();

  // eslint-disable-next-line no-unused-vars
  const { http, url_root, user_permissoes, verificarPermissao, getMissao } =
    AuthUser();
  const [utilizadores, setUtilizador] = useState([]);

  const [nomeFilter, setNomeFilter] = useState("");
  const [telefoneFilter, setTelefoneFilter] = useState("");
  const [emailFilter, setEmailFilter] = useState("");
  const [estadoFilter, setEstadoFilter] = useState(-1);

  const [cargo_id, setCargoID] = useState(-1);
  const [departamento_id, setDepartamentoID] = useState(-1);

  const [ano, setAno] = useState(2024);
  const [mes, setMes] = useState(1);

  const [itemFocus, setItemFocus] = useState(false);

  // State to track whether the "checkgeral" checkbox is checked
  const [loading, setLoading] = useState(false);

  const [dropdownVisible, setDropdownVisible] = useState(false);

  const toggleDropdown = () => {
    setDropdownVisible((prevVisible) => !prevVisible);
  };

  const inputFocus = () => {
    setItemFocus(true);
  };

  const inputBlur = () => {
    setItemFocus(false);
  };

  const handleFilterSubmit = async () => {
    try {
      console.log("consultar");

      setLoading(true);

      // Envie uma solicitação para pesquisar usuario
      const formData = new FormData();
      formData.append("nome", nomeFilter);
      formData.append("telefone", telefoneFilter);
      formData.append("email", emailFilter);
      formData.append("estado", estadoFilter);
      formData.append("tipo", tipo);
      formData.append("missao_id", getMissao()?.id);

      formData.append("cargo_id", cargo_id);
      formData.append("departamento_id", departamento_id);

      formData.append("mes", mes);
      formData.append("ano", ano);

      const response = await http.post("/utilizador/ferias", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      console.log("Dados");
      console.log(response.data.dados);

      // Update the parent component state with filtered users
      onFilterSubmit(response.data.dados);

      setLoading(false);
      
    } catch (error) {
      // Exiba uma mensagem de erro se ocorrer um problema
      toast.error(
        error?.message,
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );

      setLoading(false);
    }
  };

  const handleExportPDFfaltas = async () => {
    try {
      console.log("consultar");

      setLoading(true);

      // Envie uma solicitação para pesquisar usuario
      const formData = new FormData();
      formData.append("nome", nomeFilter);
      formData.append("telefone", telefoneFilter);
      formData.append("email", emailFilter);
      formData.append("estado", estadoFilter);
      formData.append("tipo", tipo);
      formData.append("missao_id", getMissao()?.id);

      formData.append("cargo_id", cargo_id);
      formData.append("departamento_id", departamento_id);

      formData.append("mes", mes);
      formData.append("ano", ano);

      const response = await http.post("/utilizador/presencas/pdf", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      console.log("response", response.data.url);

      setLoading(false);

      // Open the response URL in a new tab
      window.open(response?.data?.url, "_blank");
    } catch (error) {
      // Exiba uma mensagem de erro se ocorrer um problema
      toast.error(
        "Ocorreu um erro pesquisar. Por favor, tente novamente mais tarde.",
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );

      setLoading(false);
    }
  };

  const handleExportPDFferias = async () => {
    try {
      console.log("consultar");

      setLoading(true);

      // Envie uma solicitação para pesquisar usuario
      const formData = new FormData();
      formData.append("nome", nomeFilter);
      formData.append("telefone", telefoneFilter);
      formData.append("email", emailFilter);
      formData.append("estado", estadoFilter);
      formData.append("tipo", tipo);
      formData.append("missao_id", getMissao()?.id);

      formData.append("cargo_id", cargo_id);
      formData.append("departamento_id", departamento_id);

      formData.append("mes", mes);
      formData.append("ano", ano);

      const response = await http.post("/utilizador/ferias/pdf", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      console.log("response", response.data.url);

      setLoading(false);

      // Open the response URL in a new tab
      window.open(response?.data?.url, "_blank");
    } catch (error) {
      // Exiba uma mensagem de erro se ocorrer um problema
      toast.error(
        "Ocorreu um erro pesquisar. Por favor, tente novamente mais tarde.",
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        }
      );

      setLoading(false);
    }
  };

  const renderOptionCargos = (cargos) => {
    return cargos?.map((cargo) => (
      <React.Fragment key={cargo.id}>
        <option value={cargo.id}>{cargo.designacao}</option>
      </React.Fragment>
    ));
  };

  const renderOptionDepartamentos = (departamentos) => {
    return departamentos?.map((departamento) => (
      <React.Fragment key={departamento.id}>
        <option value={departamento.id}>{departamento.designacao}</option>
      </React.Fragment>
    ));
  };

  const chamarModalMarcarFaltaConjunta = () => {
    const editButton = document.getElementById("triggerMarcarFaltaConjunta");

    if (editButton) {
      editButton.click();
    }
  };

  const chamarModalMarcarFeriaConjunta = () => {
    const editButton = document.getElementById("triggerMarcarFeriaConjunta");

    if (editButton) {
      editButton.click();
    }
  };

  return (
    <div className="row filter-row space">

      <div className="col-sm-6 col-md-1">
        <div className="input-block form-focus select-focus">
          <label className="focus-label">{t("Ano")}</label>
          <select
            onChange={(e) => setAno(e.target.value)}
            value={ano}
            className="form-control select"
          >
            <option value={-1}></option>
            <option value="2023">2023</option>
            <option value="2024">2024</option>
            <option value="2025">2025</option>
          </select>
        </div>
      </div>
    
      <div className="col-sm-6 col-md-6">
        <div
          className={`input-block mb-3 form-focus  ${
            itemFocus ? "focused" : ""
          } `}
        >
          <input
            type="text"
            name="nameFilter"
            className="form-control floating"
            onFocus={inputFocus}
            onBlur={inputBlur}
            value={nomeFilter}
            onChange={(e) => setNomeFilter(e.target.value)}
          />
          <label className="focus-label">{t("Name")}</label>
        </div>
      </div>

      <div className="col-sm-6 col-md-3">
        <div
          className={`input-block mb-3 form-focus  ${
            itemFocus ? "focused" : ""
          } `}
        >
          <input
            type="text"
            name="nameFilter"
            className="form-control floating"
            onFocus={inputFocus}
            onBlur={inputBlur}
            value={emailFilter}
            onChange={(e) => setEmailFilter(e.target.value)}
          />
          <label className="focus-label">{t("Email")}</label>
        </div>
      </div>

      <div className="col-sm-6 col-md-2">
        <div
          className={`input-block mb-3 form-focus  ${
            itemFocus ? "focused" : ""
          } `}
        >
          <input
            type="text"
            name="nameFilter"
            className="form-control floating"
            onFocus={inputFocus}
            onBlur={inputBlur}
            value={telefoneFilter}
            onChange={(e) => setTelefoneFilter(e.target.value)}
          />
          <label className="focus-label">{t("Phone")}</label>
        </div>
      </div>

      <div className="col-sm-6 col-md-2">
        <div className="input-block form-focus select-focus">
          <select
            className="form-control"
            onChange={(e) => setEstadoFilter(e.target.value)}
          >
            <option value="-1">Todos estados</option>
            <option value="0">Desativado</option>
            <option value="1">Ativado</option>
          </select>
          <label className="focus-label">{t("Status")}</label>
        </div>
      </div>

      <div className="col-sm-6 col-md-3">
        <div
          className={`input-block mb-3 form-focus  ${
            itemFocus ? "focused" : ""
          } `}
        >
          <select
            onChange={(e) => setCargoID(e.target.value)}
            value={cargo_id}
            className="form-control select"
          >
            <option value="-1">Todos cargos</option>
            <option key={0} value={-1}></option>
            {renderOptionCargos(cargos)}
          </select>
        </div>
      </div>

      <div className="col-sm-6 col-md-3">
        <div
          className={`input-block mb-3 form-focus  ${
            itemFocus ? "focused" : ""
          } `}
        >
          <select
            onChange={(e) => setDepartamentoID(e.target.value)}
            value={departamento_id}
            className="form-control select"
          >
            <option value="-1">Todos departamentos</option>
            <option key={0} value={-1}></option>
            {renderOptionDepartamentos(departamentos)}
          </select>
        </div>
      </div>

      

      <div className="col-sm-6 col-md-1">
        {!loading ? (
          <>
        <span
          className="btn btn-dark btn-block w-100"
          onClick={() => chamarModalMarcarFeriaConjunta()}
        >
          <i className="fa fa-umbrella" />
        </span>
          </>
        ) : (
          <></>
        )}
      </div>

      <div className="col-sm-6 col-md-1">
        {!loading ? (
          <>
            <span
              className="btn btn-danger btn-block w-100"
              onClick={() => handleExportPDFferias()}
              title="Ferias"
            >
              <i className="fa fa-file-pdf text-white"></i>
            </span>
          </>
        ) : (
          <></>
        )}
      </div>

      <div className="col-sm-6 col-md-1">
        {!loading ? (
          <>
            <span
              className="btn btn-success btn-block w-100"
              onClick={() => handleFilterSubmit()}
            >
              <i className="fa fa-search"></i>
            </span>
          </>
        ) : (
          <></>
        )}
      </div>

      <div className="col-sm-6 col-md-1">
        <span className="btn btn-dark btn-block w-100" onClick={toggleDropdown}>
          <i className="fa fa-cogs"></i>
        </span>
      </div>

      <MarcarFaltaConjuntaModal colaboradores={utilizadoresSelecionados} />
      <MarcarFeriaConjuntaModal colaboradores={utilizadoresSelecionados} />

      <span
        data-bs-toggle="modal"
        data-bs-target="#marcar_falta_conjunta_modal"
        id="triggerMarcarFaltaConjunta"
      ></span>

<span
        data-bs-toggle="modal"
        data-bs-target="#marcar_feria_conjunta_modal"
        id="triggerMarcarFeriaConjunta"
      ></span>

      <ToastContainer />

      {loading ? (
        <>
          <Spinner></Spinner>
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default FeriaFilter;
